import React from "react";

const OutlineLogo = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" height="50" viewBox="0 0 2000 500" enable-background="new 0 0 2000 500" >
        <path stroke="#FFFFFF" stroke-width="10" fill-opacity="0" d="M1989.8,258c0,90.9-67.4,165-150.6,165H157.7C74.5,423,7.1,348.9,7.1,258l0,0c0-90.9,67.4-165,150.6-165
        h1681.5C1922.4,93,1989.8,167.1,1989.8,258L1989.8,258z"/>
        <g>
            <path fill="#FFFFFF" d="M110.2,195.9h-8.4c-1.9,0-3.4,0.5-4.6,1.4c-1.2,0.9-2.1,2.2-2.9,3.7c-0.7,1.6-1.3,3.3-1.6,5.2
                c-0.3,1.9-0.5,3.9-0.5,5.9v28.1c0.1,4.3-0.4,8-1.6,11c-1.2,3-2.6,5.5-4.4,7.4c-1.7,2-3.5,3.5-5.3,4.5c-1.8,1-3.3,1.6-4.4,1.9v0.6
                c1.1,0.1,2.6,0.6,4.4,1.5c1.8,0.9,3.6,2.2,5.3,4.1c1.7,1.9,3.2,4.4,4.4,7.5c1.2,3.2,1.7,7.1,1.6,11.8v27.9c0,1.9,0.2,3.7,0.5,5.7
                c0.3,1.9,0.8,3.7,1.6,5.2c0.7,1.5,1.7,2.8,2.9,3.7c1.2,0.9,2.7,1.4,4.6,1.4h8.4v13.4H96.2c-2.4,0-4.7-0.6-7-1.7
                c-2.3-1.1-4.4-2.8-6.2-5.1c-1.9-2.3-3.4-5.2-4.6-8.8c-1.2-3.6-1.8-7.9-1.8-12.8v-24.9c-0.2-6.8-1.9-12.1-4.8-16
                c-3-3.8-6.6-5.8-10.8-5.8V258c4.2,0,7.8-1.9,10.8-5.8c3-3.8,4.6-9.2,4.8-16v-29.4c0-4,0.6-7.5,1.8-10.5c1.2-3,2.7-5.6,4.6-7.6
                c1.9-2,3.9-3.6,6.2-4.6c2.3-1.1,4.6-1.6,7-1.6h13.9V195.9z"/>
            <path fill="#FFFFFF" d="M191.2,253.2c-3.1-3.2-6.4-5.7-9.8-7.3c-3.4-1.7-7.5-2.5-12.2-2.5c-4.6,0-8.6,0.8-12,2.5
                c-3.4,1.7-6.3,4-8.6,6.9c-2.3,2.9-4,6.3-5.2,10c-1.2,3.8-1.8,7.7-1.8,11.8s0.7,8,2,11.6c1.4,3.7,3.3,6.8,5.8,9.6
                c2.5,2.7,5.5,4.9,8.9,6.4c3.5,1.6,7.4,2.3,11.7,2.3c4.7,0,8.7-0.8,12.1-2.5c3.3-1.7,6.4-4.1,9.3-7.3l11.9,11.9
                c-4.3,4.8-9.4,8.3-15.2,10.4c-5.8,2.1-11.9,3.2-18.3,3.2c-6.8,0-13-1.1-18.7-3.3c-5.6-2.2-10.5-5.4-14.6-9.4
                c-4.1-4-7.2-8.9-9.5-14.5c-2.2-5.6-3.3-11.9-3.3-18.7c0-6.8,1.1-13.1,3.3-18.8c2.2-5.7,5.4-10.6,9.4-14.7c4-4.1,8.9-7.3,14.5-9.6
                c5.6-2.3,11.9-3.4,18.9-3.4c6.4,0,12.6,1.1,18.5,3.4c5.9,2.3,11,5.8,15.3,10.5L191.2,253.2z"/>
            <path fill="#FFFFFF" d="M213.5,273.8c0-6.4,1.2-12.5,3.6-18c2.4-5.6,5.7-10.5,9.9-14.7c4.2-4.2,9.2-7.5,14.9-9.9
                c5.7-2.4,11.8-3.6,18.4-3.6c6.6,0,12.7,1.2,18.4,3.6c5.7,2.4,10.7,5.7,14.9,9.9c4.2,4.2,7.5,9.1,9.9,14.7c2.4,5.6,3.6,11.6,3.6,18
                c0,6.4-1.2,12.5-3.6,18.1c-2.4,5.6-5.7,10.5-9.9,14.7c-4.2,4.2-9.2,7.4-14.9,9.9c-5.7,2.4-11.8,3.6-18.4,3.6
                c-6.6,0-12.7-1.2-18.4-3.6c-5.7-2.4-10.7-5.7-14.9-9.9c-4.2-4.2-7.5-9-9.9-14.7C214.7,286.3,213.5,280.3,213.5,273.8z M231.4,273.8
                c0,4.5,0.7,8.6,2,12.4c1.4,3.8,3.3,7,5.8,9.7c2.5,2.7,5.5,4.8,9.1,6.3c3.6,1.6,7.6,2.3,12.1,2.3c4.5,0,8.5-0.8,12.1-2.3
                c3.6-1.5,6.6-3.7,9.1-6.3c2.5-2.7,4.4-5.9,5.8-9.7c1.4-3.8,2-7.9,2-12.4c0-4.5-0.7-8.6-2-12.4c-1.4-3.8-3.3-7-5.8-9.7
                c-2.5-2.7-5.5-4.8-9.1-6.3c-3.6-1.5-7.6-2.3-12.1-2.3c-4.5,0-8.5,0.8-12.1,2.3c-3.6,1.6-6.6,3.7-9.1,6.3c-2.5,2.7-4.4,5.9-5.8,9.7
                C232.1,265.2,231.4,269.4,231.4,273.8z"/>
            <path fill="#FFFFFF" d="M416.5,317.9h-16.7v-12.6h-0.4c-3.2,4.7-7.7,8.4-13.3,11c-5.6,2.6-11.4,3.9-17.4,3.9
                c-6.8,0-13-1.2-18.5-3.5c-5.5-2.4-10.2-5.6-14.1-9.9c-3.9-4.2-6.9-9.1-9-14.7c-2.1-5.6-3.2-11.6-3.2-18.2c0-6.6,1.1-12.7,3.2-18.3
                c2.1-5.6,5.1-10.5,9-14.7c3.9-4.2,8.6-7.4,14.1-9.8c5.5-2.4,11.7-3.5,18.5-3.5c6.3,0,12.3,1.3,17.8,4c5.6,2.7,9.9,6.3,12.8,10.9
                h0.4v-65.1h16.7V317.9z M370.8,304.5c4.5,0,8.5-0.8,12.1-2.3c3.6-1.5,6.6-3.7,9.1-6.3c2.5-2.7,4.4-5.9,5.8-9.7
                c1.4-3.8,2-7.9,2-12.4c0-4.5-0.7-8.6-2-12.4c-1.4-3.8-3.3-7-5.8-9.7c-2.5-2.7-5.5-4.8-9.1-6.3c-3.6-1.5-7.6-2.3-12.1-2.3
                c-4.5,0-8.5,0.8-12.1,2.3c-3.6,1.6-6.6,3.7-9.1,6.3c-2.5,2.7-4.4,5.9-5.8,9.7c-1.4,3.8-2,7.9-2,12.4c0,4.5,0.7,8.6,2,12.4
                c1.4,3.8,3.3,7,5.8,9.7c2.5,2.7,5.5,4.8,9.1,6.3C362.3,303.7,366.3,304.5,370.8,304.5z"/>
            <path fill="#FFFFFF" d="M439.2,198.2c0-3.3,1.2-6.2,3.6-8.6c2.4-2.4,5.3-3.6,8.6-3.6s6.2,1.2,8.6,3.6c2.4,2.4,3.6,5.3,3.6,8.6
                s-1.2,6.2-3.6,8.6c-2.4,2.4-5.3,3.6-8.6,3.6s-6.2-1.2-8.6-3.6C440.4,204.4,439.2,201.5,439.2,198.2z M443.1,229.8h16.7v88.1h-16.7
                V229.8z"/>
            <path fill="#FFFFFF" d="M486.4,229.8h16.7v13.6h0.4c2.1-4.7,5.8-8.5,11-11.4c5.2-2.9,11.2-4.4,18-4.4c4.2,0,8.3,0.7,12.2,2
                s7.3,3.3,10.2,6c2.9,2.7,5.2,6.2,7,10.5c1.7,4.3,2.6,9.3,2.6,15.2v56.7h-16.7v-52.1c0-4.1-0.6-7.6-1.7-10.5
                c-1.1-2.9-2.6-5.3-4.5-7.1c-1.9-1.8-4-3.1-6.4-3.9c-2.4-0.8-4.9-1.2-7.5-1.2c-3.5,0-6.7,0.6-9.7,1.7c-3,1.1-5.6,2.9-7.8,5.3
                c-2.2,2.4-4,5.5-5.2,9.2c-1.2,3.7-1.9,8.1-1.9,13.2v45.4h-16.7V229.8z"/>
            <path fill="#FFFFFF" d="M678.1,317.1c0,6.7-1.1,12.8-3.4,18.3c-2.3,5.5-5.5,10.3-9.8,14.3c-4.2,4-9.3,7.2-15.2,9.4
                c-5.9,2.2-12.5,3.3-19.7,3.3c-8.4,0-16.1-1.2-23.1-3.5c-7-2.4-13.7-6.4-20-12.3l11.3-14.1c4.3,4.7,9,8.3,14.1,10.7
                c5.1,2.4,10.8,3.6,17.3,3.6c6.2,0,11.3-0.9,15.4-2.7c4.1-1.8,7.3-4.1,9.8-7c2.4-2.9,4.1-6.1,5.1-9.8c1-3.7,1.5-7.3,1.5-11.1v-13
                h-0.6c-3.2,5.3-7.6,9.3-13.1,11.8c-5.5,2.5-11.3,3.8-17.4,3.8c-6.4,0-12.4-1.1-17.9-3.4c-5.5-2.3-10.3-5.5-14.2-9.5
                c-4-4-7.1-8.8-9.3-14.3c-2.2-5.5-3.3-11.5-3.3-17.9c0-6.4,1.1-12.5,3.2-18.1c2.1-5.6,5.1-10.6,9-14.8c3.9-4.2,8.6-7.5,14.1-9.9
                c5.5-2.4,11.7-3.5,18.5-3.5c5.9,0,11.7,1.3,17.4,3.9c5.6,2.6,10.1,6.3,13.3,11h0.4v-12.6h16.7V317.1z M632.4,243.2
                c-4.5,0-8.5,0.8-12.1,2.3c-3.6,1.6-6.6,3.7-9.1,6.3c-2.5,2.7-4.4,5.9-5.8,9.7c-1.4,3.8-2,7.9-2,12.4c0,8.9,2.6,16.1,7.8,21.5
                c5.2,5.4,12.3,8.1,21.2,8.1c8.9,0,16-2.7,21.2-8.1c5.2-5.4,7.8-12.5,7.8-21.5c0-4.5-0.7-8.6-2-12.4c-1.4-3.8-3.3-7-5.8-9.7
                c-2.5-2.7-5.5-4.8-9.1-6.3C640.8,243.9,636.8,243.2,632.4,243.2z"/>
            <path fill="#FFFFFF" d="M823.1,317.9h-24l-16.2-17.5c-5.6,6.7-11.5,11.8-17.8,15.4c-6.3,3.6-13.8,5.4-22.6,5.4
                c-7.4,0-13.9-1.1-19.3-3.3c-5.5-2.2-9.9-5-13.5-8.6c-3.5-3.6-6.1-7.7-7.8-12.2c-1.7-4.5-2.5-9.2-2.5-14c0-4.6,0.8-8.8,2.4-12.5
                c1.6-3.8,3.8-7.2,6.5-10.3c2.7-3.1,5.9-5.9,9.6-8.4c3.7-2.5,7.5-4.6,11.4-6.5c-4.2-4.5-7.8-9-10.9-13.8c-3-4.7-4.6-10.3-4.6-16.7
                c0-5,1-9.4,2.9-13.2c1.9-3.8,4.5-7.1,7.7-9.7c3.2-2.6,6.9-4.6,11.2-5.9c4.2-1.4,8.7-2,13.4-2c4.6,0,9,0.6,13.1,1.9
                c4.2,1.2,7.8,3.1,10.9,5.6c3.1,2.5,5.5,5.6,7.3,9.3c1.8,3.7,2.7,8.1,2.7,13c0,4.1-0.8,7.8-2.4,11.2c-1.6,3.4-3.7,6.5-6.3,9.3
                s-5.5,5.3-8.7,7.4c-3.2,2.2-6.5,4.1-9.9,5.9l26,27.1l16.5-27h20.3l-25.5,39.4L823.1,317.9z M740.5,255.8c-2.6,1.5-5.2,3.1-7.9,4.8
                c-2.7,1.7-5.1,3.7-7.2,5.8c-2.1,2.1-3.8,4.5-5.1,7.2c-1.3,2.7-2,5.7-2,9c0,3.2,0.7,6.2,2,9c1.4,2.8,3.2,5.2,5.6,7.3
                c2.4,2.1,5,3.8,7.9,4.9c2.9,1.2,6,1.8,9.2,1.8c3.2,0,6.2-0.5,9-1.5c2.8-1,5.4-2.3,7.8-3.8c2.4-1.5,4.6-3.3,6.7-5.3
                c2-2,3.9-4,5.7-6.1L740.5,255.8z M765.3,213.8c0-4.3-1.5-7.8-4.5-10.3c-3-2.5-6.8-3.8-11.5-3.8c-4.7,0-8.8,1.3-12.3,4
                c-3.5,2.7-5.2,6.5-5.2,11.4c0,2.2,0.4,4.3,1.2,6.3c0.8,2,1.9,3.9,3.2,5.8c1.3,1.9,2.7,3.6,4.2,5.2c1.5,1.6,3,3.2,4.5,4.6
                c2.2-1.2,4.6-2.6,7-4.1c2.4-1.5,4.6-3.2,6.6-5c2-1.9,3.6-3.9,4.9-6.2C764.6,219.4,765.3,216.8,765.3,213.8z"/>
            <path fill="#FFFFFF" d="M957.3,317.9h-24l-16.2-17.5c-5.6,6.7-11.5,11.8-17.8,15.4c-6.3,3.6-13.8,5.4-22.6,5.4
                c-7.4,0-13.9-1.1-19.3-3.3c-5.5-2.2-9.9-5-13.5-8.6c-3.5-3.6-6.1-7.7-7.8-12.2c-1.7-4.5-2.5-9.2-2.5-14c0-4.6,0.8-8.8,2.4-12.5
                c1.6-3.8,3.8-7.2,6.5-10.3c2.7-3.1,5.9-5.9,9.6-8.4c3.7-2.5,7.5-4.6,11.4-6.5c-4.2-4.5-7.8-9-10.9-13.8c-3-4.7-4.6-10.3-4.6-16.7
                c0-5,1-9.4,2.9-13.2c1.9-3.8,4.5-7.1,7.7-9.7c3.2-2.6,6.9-4.6,11.2-5.9c4.2-1.4,8.7-2,13.4-2c4.6,0,9,0.6,13.1,1.9
                c4.2,1.2,7.8,3.1,10.9,5.6c3.1,2.5,5.5,5.6,7.3,9.3c1.8,3.7,2.7,8.1,2.7,13c0,4.1-0.8,7.8-2.4,11.2c-1.6,3.4-3.7,6.5-6.3,9.3
                s-5.5,5.3-8.7,7.4c-3.2,2.2-6.5,4.1-9.9,5.9l26,27.1l16.5-27h20.3l-25.5,39.4L957.3,317.9z M874.8,255.8c-2.6,1.5-5.2,3.1-7.9,4.8
                c-2.7,1.7-5.1,3.7-7.2,5.8c-2.1,2.1-3.8,4.5-5.1,7.2c-1.3,2.7-2,5.7-2,9c0,3.2,0.7,6.2,2,9c1.4,2.8,3.2,5.2,5.6,7.3
                c2.4,2.1,5,3.8,7.9,4.9c2.9,1.2,6,1.8,9.2,1.8c3.2,0,6.2-0.5,9-1.5c2.8-1,5.4-2.3,7.8-3.8c2.4-1.5,4.6-3.3,6.7-5.3
                c2-2,3.9-4,5.7-6.1L874.8,255.8z M899.5,213.8c0-4.3-1.5-7.8-4.5-10.3c-3-2.5-6.8-3.8-11.5-3.8c-4.7,0-8.8,1.3-12.3,4
                c-3.5,2.7-5.2,6.5-5.2,11.4c0,2.2,0.4,4.3,1.2,6.3c0.8,2,1.9,3.9,3.2,5.8c1.3,1.9,2.7,3.6,4.2,5.2c1.5,1.6,3,3.2,4.5,4.6
                c2.2-1.2,4.6-2.6,7-4.1c2.4-1.5,4.6-3.2,6.6-5c2-1.9,3.6-3.9,4.9-6.2C898.8,219.4,899.5,216.8,899.5,213.8z"/>
            <path fill="#FFFFFF" d="M1034.8,253.2c-3.1-3.2-6.4-5.7-9.8-7.3c-3.4-1.7-7.5-2.5-12.2-2.5c-4.6,0-8.6,0.8-12,2.5
                c-3.4,1.7-6.3,4-8.6,6.9c-2.3,2.9-4,6.3-5.2,10c-1.2,3.8-1.8,7.7-1.8,11.8s0.7,8,2,11.6c1.4,3.7,3.3,6.8,5.8,9.6
                c2.5,2.7,5.5,4.9,8.9,6.4c3.5,1.6,7.4,2.3,11.7,2.3c4.7,0,8.7-0.8,12.1-2.5c3.3-1.7,6.4-4.1,9.3-7.3l11.9,11.9
                c-4.3,4.8-9.4,8.3-15.2,10.4c-5.8,2.1-11.9,3.2-18.3,3.2c-6.8,0-13-1.1-18.7-3.3c-5.6-2.2-10.5-5.4-14.6-9.4
                c-4.1-4-7.2-8.9-9.5-14.5c-2.2-5.6-3.3-11.9-3.3-18.7c0-6.8,1.1-13.1,3.3-18.8c2.2-5.7,5.4-10.6,9.4-14.7c4-4.1,8.9-7.3,14.5-9.6
                c5.6-2.3,11.9-3.4,18.9-3.4c6.4,0,12.6,1.1,18.5,3.4c5.9,2.3,11,5.8,15.3,10.5L1034.8,253.2z"/>
            <path fill="#FFFFFF" d="M1057.1,273.8c0-6.4,1.2-12.5,3.6-18c2.4-5.6,5.7-10.5,9.9-14.7c4.2-4.2,9.2-7.5,14.9-9.9
                c5.7-2.4,11.8-3.6,18.4-3.6c6.6,0,12.7,1.2,18.4,3.6c5.7,2.4,10.7,5.7,14.9,9.9c4.2,4.2,7.5,9.1,9.9,14.7c2.4,5.6,3.6,11.6,3.6,18
                c0,6.4-1.2,12.5-3.6,18.1c-2.4,5.6-5.7,10.5-9.9,14.7c-4.2,4.2-9.2,7.4-14.9,9.9c-5.7,2.4-11.8,3.6-18.4,3.6
                c-6.6,0-12.7-1.2-18.4-3.6c-5.7-2.4-10.7-5.7-14.9-9.9c-4.2-4.2-7.5-9-9.9-14.7C1058.3,286.3,1057.1,280.3,1057.1,273.8z
                    M1075,273.8c0,4.5,0.7,8.6,2,12.4c1.4,3.8,3.3,7,5.8,9.7c2.5,2.7,5.5,4.8,9.1,6.3c3.6,1.6,7.6,2.3,12.1,2.3
                c4.5,0,8.5-0.8,12.1-2.3c3.6-1.5,6.6-3.7,9.1-6.3c2.5-2.7,4.4-5.9,5.8-9.7c1.4-3.8,2-7.9,2-12.4c0-4.5-0.7-8.6-2-12.4
                c-1.4-3.8-3.3-7-5.8-9.7c-2.5-2.7-5.5-4.8-9.1-6.3c-3.6-1.5-7.6-2.3-12.1-2.3c-4.5,0-8.5,0.8-12.1,2.3c-3.6,1.6-6.6,3.7-9.1,6.3
                c-2.5,2.7-4.4,5.9-5.8,9.7C1075.7,265.2,1075,269.4,1075,273.8z"/>
            <path fill="#FFFFFF" d="M1171.8,229.8h15.6v13.8h0.4c0.4-1.2,1.3-2.8,2.9-4.6c1.5-1.9,3.5-3.6,5.9-5.3c2.4-1.7,5.3-3.1,8.6-4.3
                c3.3-1.2,6.9-1.8,10.9-1.8c6.6,0,12.1,1.4,16.5,4.1c4.5,2.7,8.1,6.8,11,12.3c2.8-5.5,6.9-9.5,12.1-12.3c5.2-2.7,10.5-4.1,15.8-4.1
                c6.8,0,12.4,1.1,16.7,3.3c4.3,2.2,7.7,5.2,10.2,8.8c2.5,3.7,4.2,7.8,5.1,12.5s1.4,9.5,1.4,14.4v51.3h-16.7v-49.1
                c0-3.3-0.2-6.6-0.7-9.7c-0.4-3.1-1.3-5.8-2.7-8.2c-1.4-2.4-3.3-4.2-5.8-5.7c-2.5-1.4-5.8-2.1-9.9-2.1c-8.1,0-13.8,2.5-17.3,7.4
                c-3.5,5-5.2,11.3-5.2,19.1v48.1H1230v-46.1c0-4.2-0.2-8.1-0.7-11.5c-0.4-3.5-1.3-6.5-2.6-9c-1.3-2.5-3.2-4.5-5.6-5.9
                c-2.4-1.4-5.7-2.1-9.8-2.1c-3,0-5.9,0.6-8.6,1.8c-2.8,1.2-5.2,2.9-7.3,5.3c-2.1,2.4-3.8,5.4-5,9c-1.2,3.7-1.9,8-1.9,12.9v45.7
                h-16.7V229.8z"/>
            <path fill="#FFFFFF" d="M1330.2,229.8h15.6v13.8h0.4c0.4-1.2,1.3-2.8,2.9-4.6c1.5-1.9,3.5-3.6,5.9-5.3c2.4-1.7,5.3-3.1,8.6-4.3
                c3.3-1.2,6.9-1.8,10.9-1.8c6.6,0,12.1,1.4,16.5,4.1c4.5,2.7,8.1,6.8,11,12.3c2.8-5.5,6.9-9.5,12.1-12.3c5.2-2.7,10.5-4.1,15.8-4.1
                c6.8,0,12.4,1.1,16.7,3.3c4.3,2.2,7.7,5.2,10.2,8.8c2.5,3.7,4.2,7.8,5.1,12.5s1.4,9.5,1.4,14.4v51.3h-16.7v-49.1
                c0-3.3-0.2-6.6-0.7-9.7c-0.4-3.1-1.3-5.8-2.7-8.2c-1.4-2.4-3.3-4.2-5.8-5.7c-2.5-1.4-5.8-2.1-9.9-2.1c-8.1,0-13.8,2.5-17.3,7.4
                c-3.5,5-5.2,11.3-5.2,19.1v48.1h-16.7v-46.1c0-4.2-0.2-8.1-0.7-11.5c-0.4-3.5-1.3-6.5-2.6-9c-1.3-2.5-3.2-4.5-5.6-5.9
                c-2.4-1.4-5.7-2.1-9.8-2.1c-3,0-5.9,0.6-8.6,1.8c-2.8,1.2-5.2,2.9-7.3,5.3c-2.1,2.4-3.8,5.4-5,9c-1.2,3.7-1.9,8-1.9,12.9v45.7
                h-16.7V229.8z"/>
            <path fill="#FFFFFF" d="M1566.7,317.9h-16.7v-13.6h-0.4c-2.1,4.7-5.8,8.5-11,11.4s-11.2,4.4-18,4.4c-4.3,0-8.4-0.7-12.3-2
                c-3.8-1.3-7.2-3.3-10.1-6c-2.9-2.7-5.2-6.2-7-10.5c-1.7-4.3-2.6-9.3-2.6-15.2v-56.7h16.7v52.1c0,4.1,0.6,7.6,1.7,10.5
                c1.1,2.9,2.6,5.3,4.5,7.1c1.9,1.8,4,3.1,6.4,3.9c2.4,0.8,4.9,1.2,7.5,1.2c3.5,0,6.7-0.6,9.7-1.7s5.6-2.9,7.8-5.3
                c2.2-2.4,4-5.5,5.2-9.2c1.2-3.7,1.9-8.1,1.9-13.2v-45.4h16.7V317.9z"/>
            <path fill="#FFFFFF" d="M1591.9,229.8h16.7v13.6h0.4c2.1-4.7,5.8-8.5,11-11.4c5.2-2.9,11.2-4.4,18-4.4c4.2,0,8.3,0.7,12.2,2
                s7.3,3.3,10.2,6c2.9,2.7,5.2,6.2,7,10.5c1.7,4.3,2.6,9.3,2.6,15.2v56.7h-16.7v-52.1c0-4.1-0.6-7.6-1.7-10.5
                c-1.1-2.9-2.6-5.3-4.5-7.1c-1.9-1.8-4-3.1-6.4-3.9c-2.4-0.8-4.9-1.2-7.5-1.2c-3.5,0-6.7,0.6-9.7,1.7c-3,1.1-5.6,2.9-7.8,5.3
                c-2.2,2.4-4,5.5-5.2,9.2c-1.2,3.7-1.9,8.1-1.9,13.2v45.4h-16.7V229.8z"/>
            <path fill="#FFFFFF" d="M1692.7,198.2c0-3.3,1.2-6.2,3.6-8.6c2.4-2.4,5.3-3.6,8.6-3.6c3.3,0,6.2,1.2,8.6,3.6
                c2.4,2.4,3.6,5.3,3.6,8.6s-1.2,6.2-3.6,8.6c-2.4,2.4-5.3,3.6-8.6,3.6c-3.3,0-6.2-1.2-8.6-3.6
                C1693.9,204.4,1692.7,201.5,1692.7,198.2z M1696.6,229.8h16.7v88.1h-16.7V229.8z"/>
            <path fill="#FFFFFF" d="M1787.1,244.3h-24v40c0,2.5,0.1,4.9,0.2,7.3c0.1,2.4,0.6,4.6,1.4,6.5c0.8,1.9,2,3.5,3.7,4.6
                c1.7,1.2,4.1,1.8,7.3,1.8c2,0,4-0.2,6.1-0.6c2.1-0.4,4-1.1,5.8-2v15.2c-2,1.1-4.6,1.9-7.7,2.3c-3.2,0.4-5.6,0.7-7.3,0.7
                c-6.4,0-11.4-0.9-15-2.7s-6.1-4.1-7.8-7c-1.7-2.8-2.7-6-3-9.6c-0.3-3.5-0.5-7.1-0.5-10.7v-45.9h-19.3v-14.5h19.3V205h16.7v24.7h24
                V244.3z"/>
            <path fill="#FFFFFF" d="M1798.3,229.8h19.3l26.1,68.8h0.4l25-68.8h17.8l-41.8,107.1c-1.5,3.8-3,7.3-4.6,10.5s-3.5,5.9-5.7,8.1
                c-2.2,2.2-4.9,4-8.1,5.2c-3.1,1.2-7,1.9-11.6,1.9c-2.5,0-5-0.2-7.5-0.5c-2.5-0.3-5-1-7.3-2l2-15.2c3.3,1.4,6.6,2,10,2
                c2.6,0,4.8-0.3,6.6-1c1.8-0.7,3.3-1.7,4.6-3c1.3-1.3,2.4-2.8,3.2-4.6c0.9-1.7,1.7-3.7,2.6-5.9l5.4-13.9L1798.3,229.8z"/>
            <path fill="#FFFFFF" d="M1894.4,334.2h8.4c1.9,0,3.4-0.5,4.6-1.4c1.2-0.9,2.1-2.2,2.9-3.7c0.7-1.6,1.3-3.3,1.6-5.2
                c0.3-1.9,0.5-3.9,0.5-5.9V290c-0.1-4.3,0.4-8,1.6-11c1.2-3,2.6-5.5,4.4-7.4c1.7-2,3.5-3.5,5.3-4.5c1.8-1,3.3-1.6,4.4-1.9v-0.6
                c-1.1-0.1-2.6-0.6-4.4-1.5c-1.8-0.9-3.6-2.2-5.3-4.1c-1.7-1.9-3.2-4.4-4.4-7.5c-1.2-3.2-1.7-7.1-1.6-11.8v-27.9
                c0-1.9-0.2-3.7-0.5-5.7c-0.3-1.9-0.8-3.7-1.6-5.2c-0.7-1.5-1.7-2.8-2.9-3.7c-1.2-0.9-2.7-1.4-4.6-1.4h-8.4v-13.4h13.9
                c2.4,0,4.7,0.6,7,1.7c2.3,1.1,4.4,2.8,6.2,5.1c1.9,2.3,3.4,5.2,4.6,8.8c1.2,3.6,1.8,7.9,1.8,12.8v24.9c0,6.8,1.5,12.1,4.6,16
                c3.1,3.8,6.8,5.8,11,5.8v14.5c-4.2,0-7.9,1.9-11,5.8c-3.1,3.8-4.6,9.2-4.6,16v29.4c0,4-0.6,7.5-1.8,10.5c-1.2,3-2.7,5.6-4.6,7.6
                c-1.9,2-3.9,3.6-6.2,4.6c-2.3,1.1-4.6,1.6-7,1.6h-13.9V334.2z"/>
        </g>
    </svg>

);

export default OutlineLogo;
